var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"record"},[_c('span',[_vm._v("人臉辨識測溫系統")]),_c('div',{staticClass:"record-block"},_vm._l((_vm.record),function(item,index){return _c('div',{key:index,class:{
          isFull: _vm.isFull,
          fadeOut: index < _vm.firstIndex,
          fadeIn: index === _vm.lastIndex || _vm.record.length < 3,
        }},[_c('div',{staticClass:"record-info",class:{
            isStranger: item.state === 'stranger',
            isRecord: item.state === 'recordFace',
          }},[_c('img',{staticClass:"img-face",attrs:{"src":item.log.photo.substr(0, 22) + item.log.photo.substr(22)}}),_c('img',{staticClass:"img-bg",attrs:{"src":item.state === 'stranger'
                ? require('./static/img/gray-bg.png')
                : parseInt(item.log.body_temperature, 10) > 37.5
                ? require('./static/img/red-bg.png')
                : require('./static/img/blue-bg.png')}}),_c('div',{staticClass:"record-txt"},[(item.state !== 'stranger')?_c('span',[_vm._v(_vm._s(item.log.user_name))]):_c('span',{staticClass:"text-danger"},[_vm._v("陌生人")]),_c('br'),(parseInt(item.log.body_temperature, 10) > 37.5)?_c('span',[_vm._v("體溫異常")]):(item.state !== 'stranger')?_c('span',[_vm._v("員工")]):_vm._e(),_c('br'),_c('span',[_vm._v("體溫："+_vm._s(item.log.body_temperature))])])])])}),0)]),_c('div',{staticClass:"footer"},[_c('img',{attrs:{"src":require('./static/img/footer-bg.png')}}),_c('div',{staticClass:"time-status"},[_vm._v(" "+_vm._s(_vm.timestamp)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }