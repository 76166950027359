import { mapMutations } from 'vuex'
import { getImg } from '@/api/index.js'

export const mixinWebsocket = {
  data() {
    return {
      ws: null,
    }
  },
  methods: {
    ...mapMutations(['setfaceRecord']),
    //初始websocket
    initWebsocket() {
      let baseURL =
        process.env.VUE_APP_API_URL || `face-backend.skysharp.com.tw`
      let wsURL = `wss://` + baseURL + `/people`
      this.ws = new WebSocket(wsURL) //建立連線
      this.ws.onopen = this.websocketonopen
      this.ws.error = this.websocketonerror
      this.ws.onmessage = this.websocketonmessage
      this.ws.onclose = this.websocketclose
    },
    websocketonopen() {},
    websocketonerror(e) {
      console.error('連線失敗', e)
    },
    websocketonmessage(e) {
      // 後端通知前端，前端取得資料
      let _data = e.data
      //   當有後端資料送到前端，利用vuex存到共用的state
      
      let resJson = JSON.parse(_data)
      if(resJson.state === 'recordFace'){
      getImg({
        user_id: resJson.log.user_id,
      })
        .then((res) => {
          resJson.log.photo = res.data
          this.setfaceRecord({
            data: resJson,
          })
        })
        .catch((error) => {
          console.log(error)
        })
      } else {
        this.setfaceRecord({
          data: resJson,
        })
      }
    },
    websocketclose() {},
  },
}
