import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    faceRecord: [],
    wsNotify: {},
    firstIndex: 0,
    lastIndex: 2,
  },
  mutations: {
    //當接收到最新的後端送來的websocket資訊，直接存到state中的wsNotify
    setfaceRecord(state, param) {
      let oriRecord = state.faceRecord
      oriRecord.push(param.data)
      if (state.faceRecord.length > 3) {
        Vue.set(state, 'firstIndex', state.faceRecord.length - 3)
        Vue.set(state, 'lastIndex', state.faceRecord.length - 1)
      }
      Vue.set(state, 'faceRecord', oriRecord)
    },
    deleteFirstRecord(state) {
      let oriRecord = state.faceRecord
      if (oriRecord.length > 3) {
        oriRecord.splice(0, oriRecord.length - 3)
        Vue.set(state, 'faceRecord', oriRecord)
        Vue.set(state, 'firstIndex', 0)
        Vue.set(state, 'lastIndex', 2)
      }
    },
  },
  actions: {},
  modules: {},
})
