<template>
  <div id="app">
    <div class="record">
      <span>人臉辨識測溫系統</span>
      <div class="record-block">
        <div
          v-for="(item, index) in record"
          :key="index"
          :class="{
            isFull: isFull,
            fadeOut: index < firstIndex,
            fadeIn: index === lastIndex || record.length < 3,
          }"
        >
          <div
            class="record-info"
            :class="{
              isStranger: item.state === 'stranger',
              isRecord: item.state === 'recordFace',
            }"
          >
            <img
              class="img-face"
              :src="item.log.photo.substr(0, 22) + item.log.photo.substr(22)"
            />
            <img
              class="img-bg"
              :src="
                item.state === 'stranger'
                  ? require('./static/img/gray-bg.png')
                  : parseInt(item.log.body_temperature, 10) > 37.5
                  ? require('./static/img/red-bg.png')
                  : require('./static/img/blue-bg.png')
              "
            />
            <div class="record-txt">
              <span v-if="item.state !== 'stranger'"
                >{{ item.log.user_name }}</span
              >
              <span v-else class="text-danger">陌生人</span>
              <br />
              <span v-if="parseInt(item.log.body_temperature, 10) > 37.5"
                >體溫異常</span
              >
              <span v-else-if="item.state !== 'stranger'">員工</span>
              <br />
              <span>體溫：{{ item.log.body_temperature }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <img :src="require('./static/img/footer-bg.png')" />
      <div class="time-status">
        {{ timestamp }}
      </div>
    </div>
  </div>
</template>

<script>
import { mixinWebsocket } from '@/utils/ws'
export default {
  name: 'App',
  mixins: [mixinWebsocket],
  components: {},
  data() {
    return {
      timestamp: '',
    }
  },
  computed: {
    isFull() {
      return this.$store.state.faceRecord.length > 3
    },
    record() {
      return this.$store.state.faceRecord
    },
    firstIndex() {
      return this.$store.state.firstIndex
    },
    lastIndex() {
      return this.$store.state.lastIndex
    },
  },
  created() {
    setInterval(() => {
      this.getNow()
    }, 1000)
    this.initWebsocket() //開啓前後端的websocket通道
  },
  destroy() {
    this.websocketclose() //關閉websocket通道
  },
  methods: {
    getNow: function () {
      const today = new Date()
      const date =
        today.getFullYear() +
        '/' +
        (today.getMonth() + 1 < 10 ? '0' : '') +
        (today.getMonth() + 1) +
        '/' +
        today.getDate()
      const time =
        (today.getHours() < 10 ? '0' : '') +
        today.getHours() +
        ':' +
        (today.getMinutes() < 10 ? '0' : '') +
        today.getMinutes() +
        ':' +
        (today.getSeconds() < 10 ? '0' : '') +
        today.getSeconds()
      const dateTime = date + ' ' + time
      this.timestamp = dateTime
    },
  },
  watch: {
    isFull: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.$store.commit('deleteFirstRecord')
          }, 200)
        }
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss">
@font-face {
  font-family: TaipeiSansTCBeta;
  src: url(./static/fonts/TaipeiSansTCBeta-Bold.ttf);
}
body {
  margin: 0;
}
#app {
  font-family: Avenir, TaipeiSansTCBeta;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@keyframes scale-down-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes scale-up-center {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.record {
  display: flex;
  background-image: url(./static/img/base-bg.png);
  height: calc(100vh - 58px);
  flex-direction: column;
  justify-content: space-around;
  > span {
    font-size: 38px;
  }
}
.record-block {
  display: flex;
  justify-content: center;
  font-size: 22px;
  height: 402px;
  overflow: hidden;
  margin: 0 15%;

  > div {
    margin: 0 2%;
    width: 30%;
  }
  .fadeOut {
    animation: scale-down-center 0.2s linear both;
  }
  .fadeIn {
    animation: scale-up-center 0.3s linear both;
    animation-delay: 0.2s;
  }
}
.record-info {
  display: flex;
  flex-direction: column;
  width: 288px;
  height: 402px;
  align-items: center;
  position: relative;
  .record-txt {
    position: absolute;
    z-index: 2;
    height: 40%;
    display: flex;
    flex-direction: column;
    top: 53%;
    justify-content: space-around;
  }
  .img-face {
    position: absolute;
    width: 53%;
    margin-top: 4.5%;
  }
  .img-bg {
    position: absolute;
    z-index: 1;
    width: 288px;
  }
}
.footer {
  position: relative;
  height: 58px;
  img {
    position: absolute;
    width: 100vw;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  .time-status {
    position: absolute;
    top: 50%;
    left: 10%;
    z-index: 2;
    transform: translateY(-50%);
    font-size: 30px;
  }
}
</style>
